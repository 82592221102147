.order__page {
  h1 {
    font-weight: normal;
    font-size: 24px;
    margin-bottom: 27px;

    color: #000000;
  }
  .ant-tabs-nav {
    margin-bottom: 0px;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab:nth-child(1) {
    padding: 11px 27px;
  }
  .ant-tabs-tab {
    padding: 11px 15px;
    > div {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #4a5463 !important;
    }
  }
  .ant-tabs-tab-active {
    background-color: #e7eaef;
  }
}

.order__payment__page {
  .ant-steps {
    max-width: 580px;
    margin: 0 auto 40px auto;
  }
  .ant-steps-item-content {
    width: 120px;
  }

  .ant-steps-item-subtitle {
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    color: #979797 !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background: #979797;
    .ant-steps-icon {
      color: white;
    }
  }

  .ant-steps-item-active .ant-steps-item-icon {
    background: #979797;
    .ant-steps-icon {
      color: #979797;
      -webkit-transition: color 200ms linear;
      -ms-transition: color 200ms linear;
      transition: color 200ms linear;
    }
  }

  .ant-steps-item-icon {
    background: #eeeeee;
    border: none !important;
    .ant-steps-icon {
      color: #eeeeee;
    }
  }

  .ant-steps-item-tail {
    padding: 2px 0px 2px 12px;
  }
  .ant-steps-item-tail::after {
    height: 5px;
    background-color: #eeeeee !important;
  }
}

.order__receipt__page {
  .btn_next {
    margin-top: 40px;
    text-align: right;
    .ant-btn {
      height: 54px;
      background: #c4c4c4;
      border-color: #c4c4c4;
      border-radius: 0;
      color: #ffffff;
      width: 160px;
    }
  }
}

.order__cancel__page {
  > div:nth-child(1) {
    color: #535252;
    .title_thank {
      font-size: 18px;
    }
    .sub_title_thank {
      margin-top: 3px;
      font-size: 12px;
    }
  }

  .text-right {
    text-align: right;
  }

  .reason__box {
    margin-top: 33px;
    > div {
      background-color: #f9f9f9;
      padding: 20px 50px;
    }
    > div:nth-child(1) {
      font-size: 18px;
    }
    > div:nth-child(2) {
      margin-top: 6px;
    }
  }

  .goto__order__btn {
    margin-top: 39px;
    width: 246px;
    border-radius: 2px;
    background: #535252;
    border-color: #535252;
    color: white;
  }

  .goto__home__btn {
    margin-top: 39px;
    width: 246px;
    border-radius: 2px;
    margin-right: 12px;
    background: white;
    border-color: #535252;
    color: #535252;
  }
}
