.Categories {
  .Header {
    margin-bottom: 27px;
    display: inline-flex;
    align-items: center;

    h2 {
      font-size: 24px;
      font-weight: 500;
      color: #1b1d21;
      margin: 0;
    }
    .LinkAllCategories {
      margin-top: 3px;
      font-size: 16px;
      line-height: 1;
      color: #979797;
      b {
        font-size: 12px;
        align-items: center;
      }
    }
  }
  .Cards {
    display: flex;
    flex-wrap: wrap;
  }

  .circle_btn {
    border-color: #545961;
    color: #545961;
    font-weight: bold;
  }
}
