.product_detail_page {
  margin-top: 50px;

  .detail {
    margin-top: 45px;
    color: #000000;

    #myVideo {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    .main__image {
      width: 100%;
      position: relative;
      padding-top: 100%;
      border: 1px solid #e5e5e5;
      background-repeat: no-repeat !important;
      background-size: contain !important;
      background-position: center !important;
    }

    .sub__image {
      width: 74px;
      height: 74px;
      cursor: pointer;
      background-repeat: no-repeat !important;
      background-size: contain !important;
      background-position: center !important;
    }

    .product_name {
      font-weight: bold;
      font-size: 24px;
    }

    .product_reviews {
      margin-top: 4px;
      font-size: 12px;
      display: flex;
      align-items: center;
      > div:nth-child(1) {
        padding-right: 12px;
      }
      > div:nth-child(2) {
        border-left: 1px solid #979797;
        padding-left: 12px;
        padding-top: 2px;
      }

      .Rate {
        color: #535252;
      }
    }

    .product_price {
      color: #1b1d21;
      font-weight: bold;
      font-size: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.005em;
      div:nth-child(2) {
        font-size: 12px;
        padding-top: 8px;
      }
    }

    .attribute__product {
      .ant-select {
        width: 200px;
        margin-top: 9px;
        height: 36px;
      }
      .ant-select-selector {
        height: 36px;
        input {
          height: 36px;
        }
      }
    }

    .qty__product {
      > div {
        display: flex;
        align-items: center;
        .ant-input-number {
          margin-top: 9px;
          margin-left: 0px;
          height: 36px;
        }
        > div {
          font-family: Poppins;
          font-size: 12px;
          margin-top: 10px;
          color: #000000;
          margin-left: 16px;
        }
      }
    }

    .error_note {
      color: #d12e27;
      font-size: 12px;
    }

    .add_to_cart_btn {
      background: #535252;
      border-color: #535252;
      border-radius: 0px;
      font-family: Poppins;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: white;
    }

    .disabled_btn {
      background: #e7eaef !important;
      border-color: #e7eaef !important;
      color: #535252;
    }
  }

  .desciption__tabs {
    margin-top: 70px;

    .ant-tabs-nav {
      margin-bottom: 35px;
    }

    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-tab {
      padding: 11px 15px;
      margin: 0px;
      > div {
        font-family: Poppins;
        font-size: 12px;
        color: #4a5463 !important;
      }
    }

    .ant-tabs-tab-active {
      background-color: #e7eaef;
    }

    .ant-tabs-nav::before {
      border-bottom: none;
    }

    .about_shop {
      display: inline-flex;
      margin-bottom: 49px;
      > div:nth-child(1) {
        height: 100px;
        width: 100px;
        background: #e5e5e5;
        margin-right: 25px;
      }

      > div:nth-child(2) {
        font-family: Poppins;
        font-weight: normal;
        font-size: 18px;
        color: #535252;

        .chat__btn {
          background: #535252;
          border-color: #535252;
          border-radius: 0px;
          font-family: Poppins;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: white;
          width: 100px;
          height: 27px;
          margin-top: 5px;
        }
      }
    }

    .about_product {
      padding: 25px;
      border: 1px solid #eeeeee;

      > div:nth-child(1) {
        margin-bottom: 20px;
        font-weight: normal;
        font-size: 18px;
        color: #222122;
      }
    }

    .product__review__section {
      .product__views:not(:last-child) {
        margin-bottom: 28px;
      }
    }
  }

  .more__btn {
    margin-top: 40px;

    .ant-divider {
      border-top-color: #d8d8d8;
    }

    button {
      background: #c4c4c4;
      border-color: #c4c4c4;
      border: none;
      border-radius: 2px;
      color: #535252;
      font-size: 14px;
      width: 174px;
    }
  }
}
