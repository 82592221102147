.CategoryPage {
  .more__btn {
    margin-top: 60px;

    .ant-divider {
      border-top-color: #d8d8d8;
    }

    button {
      background: #c4c4c4;
      border-color: #c4c4c4;
      border: none;
      border-radius: 2px;
      color: #535252;
      font-size: 14px;
      width: 174px;
    }
  }
}
