.modal_action_address {
  padding-top: 24px;
  .btn {
    border-radius: 2px;
    border-color: #535252;
    width: 146px;

    &.confirm {
      color: white;
      background: #535252;
    }

    &.cancel {
      margin-right: 12px;
      background: white;
      color: #535252;
    }
  }
}
