.layout__component {
  max-width: 1175px;
  margin: 0 auto;
  margin-bottom: 60px;
  > section {
    width: 100%;
    margin: 0 auto;
    padding: 40px 60px;
    min-height: 500px;
  }
}
