.sldes_components {
  position: relative;
  .slick-dots > li {
    height: 8px !important;
    width: 8px !important;
    > button {
      border-radius: 100%;
      width: 100%;
      height: 100%;
      background-color: #979797 !important;
    }
  }

  .carousel__child {
    width: 100%;
    // height: 542px;
    padding-top: 46.127%;
    position: relative; 
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &.slide_1 {
      background-image: url("../../../common/banner/banner1.svg");
    }

    &.slide_2 {
      background-image: url("../../../common/banner/banner2.svg");
    }

    &.slide_3 {
      background-image: url("../../../common/banner/banner3.svg");
    }
  }

  .sub__text {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 542px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Poppins;

    > div:nth-child(1) {
      color: white;
      font-size: 48px;
      line-height: 50px;
      text-align: center;
    }

    > div:nth-child(2) {
      margin-top: 24px;
      font-size: 14px;
      color: #535252;
      text-align: center;
    }

    > div:nth-child(3) {
      margin-top: 36px;
    }

    button {
      width: 211px;
      font-size: 16px;
      color: white;
      border-color: #535252;
      background: #535252;
    }
  }
}
