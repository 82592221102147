.table_cart {
  min-width: 700px;
  th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5) {
    text-align: center !important;
  }

  tr {
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6) {
      text-align: center !important;
    }
  }
  tr {
    td {
      background: none !important;
      .product_name {
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        width: 100%;
        font-weight: 600;
      }
      .product_varient {
        // display: -webkit-box;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        width: 100%;
      }
    }
  }

  margin-bottom: 48px;

  .head-table {
    font-size: 18;
    color: #58595b;
  }
  .ant-pagination.ant-table-pagination.ant-table-pagination-right {
    display: none;
  }
  Table {
    width: 100%;
    border-bottom: 0.5px solid #979797;
  }
  .ant-table-thead .ant-table-cell {
    background-color: white;
    border-bottom: 0.5px solid #979797;
  }
  .ant-table-cell {
    background-color: white;
    border: none;
  }
  .ant-table-cell::before {
    display: none;
  }
  .DeleteOut {
    color: black;
  }

  .ant-input-number-input-wrap {
    input {
      text-align: center;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1bd27e;
    border-color: #1bd27e;
    border: 1px solid #1bd27e !important;
  }

  .ant-checkbox-inner::after {
    background-color: #1bd27e;
  }
}
