@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Text&display=swap");

.RegisterForm {
  padding: 0px;
  margin: 51px 18% 118px 18%;
  .header {
    text-align: center;
    margin-bottom: 21px;
    h2 {
      font-size: 33.31px;
      font-family: "DM Serif Text", serif;
      text-align: center;
      font-style: normal;
      margin: 0;
      color: #535252;
    }
  }

  p {
    color: #666666;
    text-align: center;
    margin-bottom: 50px;
  }

  .TermsAndPrivacy {
    color: #696868;
    a {
      color: #696868;
      text-decoration: underline;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #979797;
    border-color: #979797;
  }

  .SubmitForm {
    background-color: #535252;
    border-color: #535252;
    width: 90%;
    max-width: 400px;
    height: 36px;
    margin: 40px auto;
    display: block;
  }

  * {
    font-family: Poppins;
  }

  .ant-input,
  .ant-select-selector {
    height: 36px !important;
    font-family: Poppins;
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }

  .customer__selector {
    font-family: Poppins;
    font-weight: normal;
    font-size: 13px;
    color: #080c16;
    display: flex;
    align-items: center;
    justify-items: space-between;
    margin-right: 8px;
    height: 28px;
    border-color: #f2f4f6;
    background: #f2f4f6;
    border-radius: 4px;
    padding: 8px;
  }

  .ant-form-item-explain {
    margin-top: 4px;
  }

  .btn__disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
}
