.order__table {
  overflow-x: scroll;
  overflow-y: hidden;
  table {
    width: 100%;
    min-width: 1050px;
    tr {
      background: #ffffff;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) !important;
      min-height: 58px;
      height: 100%;
      margin: 7px 0;
      display: flex;
      width: 100%;
      align-items: center;
    }
    th,
    td {
      padding: 16px;
      text-align: left;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
    }
    thead {
      tr {
        margin: 12px 0;
        color: #464a53;
      }
    }
    tbody {
      tr {
        color: #000000;
      }
      tr:nth-child(even) {
        background-color: #fafafa;
      }
    }
  }

  .order__btn {
    border-radius: 0px;
    color: white;
    background: #979797;
    border-color: #979797;
    font-size: 12px;
    width: 100px;
  }
}
