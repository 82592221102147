.RegisterComplete {
  padding: 0px;
  margin: 0 18%;
  padding-top: 15%;
  text-align: center;
  position: relative;

  .header {
    color: #58595b;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
  }

  .detail {
    margin-top: 24px;
    margin-bottom: 41px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #979797;
  }

  .btn {
    button {
      background-color: #58595b;
      border-color: #58595b;
      height: 36px;
      color: white;
      width: 90%;
      max-width: 400px;
    }
  }
}
