@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Text&family=Inter:wght@400;600&family=Poppins&display=swap");

.CartCard {
  .ant-pagination.ant-table-pagination.ant-table-pagination-right {
    display: none;
  }

  .header-table {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    margin: 18px 0px;
  }

  &:nth-child(1) {
    .header-table {
      margin-top: 0;
    }
  }

  Table {
    min-width: 400px;
    font-size: 14px;
    .ant-table-cell {
      background-color: white;
      border: none;
    }
    .ant-table-cell::before {
      display: none;
    }
    .DeleteOut {
      color: black;
    }
    .ant-table-thead .ant-table-cell.ant-table-selection-column {
      visibility: hidden;
    }
    .productName {
      display: flex;
      align-items: center;
    }
    img {
      background-color: #e5e5e5;
      height: 77px;
      width: 73px;
      min-width: none;
      margin-right: 10px;
    }
    .head {
      font-weight: 500;
      line-height: 18px;
      margin-bottom: 9px;
    }
    .body {
      font-weight: 500;
      line-height: 18px;
    }
    td.ant-table-cell {
      color: #838b9e;
      .product_name {
        // display: -webkit-box;
        // -webkit-line-clamp: 3;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        width: 100%;
        font-weight: 600;
      }
      .product_variant {
        // display: -webkit-box;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        width: 100%;
      }
    }
  }
}
