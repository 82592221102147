@import "./style/fonts/fonts.scss";

html,
body {
  font-family: Roboto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-btn {
  border-radius: 4px;
}

.error__input__text {
  font-size: 12px;
  color: rgb(248, 108, 108);
  margin-top: 8px;
}

.loading-app-screen {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  position: fixed;
  background-color: #0000001f;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(24px, 0);
    }
  }

  .timeInput input {
    padding: 11px 14px;
  }

  .my-5-x {
    margin-top: 5px;
    margin-bottom: 1.5rem;
  }

  .grid_route_pin.isFilter button {
    border: 2px solid #03c767;
  }
}

.load__more__btn {
  border-radius: 2px;
  margin-top: 24px;
  width: 180px;
  border-color: #525252 !important;
  background: #525252 !important;
  color: white !important;
  font-family: "Poppins";
}

.text-center {
  text-align: center;
}
