.Cart {
  h2 {
    font-weight: 400;
    font-size: 24px;
  }
  .Body {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 27px;
    .SubHead {
      font-weight: 600;
      font-size: 14px;
    }
    .Store {
      flex: 1;
      overflow-x: scroll;
    }
    .Summary {
      padding: 24px 36px;
      flex: 1;
      max-width: 286px;
      height: fit-content;
      border: 0.5px solid #979797;
      display: flex;
      justify-content: center;
      flex-direction: column;
      h3 {
        margin-bottom: 18px;
        font-size: 18px;
        font-weight: 500;
      }
      .NameSummary {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 14px;
      }
      .Order {
        background-color: #535252;
        color: white;
        width: 100%;
        max-width: 195px;
        margin: 0 auto;
      }
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Text&family=Inter:wght@400;600&family=Poppins&display=swap");

.CartConfirm {
  font-family: "Poppins", sans-serif;
  .header {
    font-size: 24px;
    margin-bottom: 35px;
  }
  .Body {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .SubHead {
      font-weight: 600;
      font-size: 14px;
    }
    .Store {
      border-radius: 2px;
      background-color: #eeeeee;
      padding: 24px !important;
      overflow-x: scroll;
    }
    .NoticationShipping {
      font-family: Roboto, sans-serif;
      .header {
        background: #eeeeee;
        height: 42px;
        color: #535252;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        margin: 0px;
        display: flex;
        align-items: center;
        padding-left: 23px;
      }
      .ListAddress {
        border-radius: 2px;
        background: #f4f4f4;
        color: #000000;
        width: 100%;
        margin: 0px 0 12px 0;
        padding: 21px 23px;
        position: relative;
        .HeadAddress {
          font-weight: 500;
          font-size: 14px;
          padding-left: 12px;
        }

        .DetailAddress {
          padding-left: 12px;
          word-wrap: break-word;
          width: 409px;
          font-weight: 400;
          font-size: 13px;
        }
        .ant-radio-checked .ant-radio-inner {
          border-color: #979797 !important ;
        }
        .ant-radio-checked .ant-radio-inner:after {
          background-color: #979797;
        }
        .ant-radio:hover .ant-radio-inner {
          border-color: #979797;
        }
      }
      .NewAddress {
        background: #eeeeee;
        color: #535252;
        width: 100%;
        border-radius: 2px;
        border-color: #eeeeee;
        height: 36px;
      }
      .Border {
        margin: 20px -12px;
        border-bottom: 1px solid #e5e5e5;
      }
      .Summary {
        margin-top: 12px;
        padding: 31px 42px;
        height: fit-content;
        border: 0.5px solid #979797;
        .NameSummary {
          display: flex;
          justify-content: space-between;
          font-weight: 500;
          font-size: 14px;
        }
        .Order {
          background-color: #535252;
          color: white;
          width: 100%;
        }
      }
    }

    .order_btn_1 {
      margin-top: 25px;
      height: 35px;
      background: #535252;
      border-color: #535252;
      color: white;
      border-radius: 2px;
      width: 168px;
    }

    .order_btn_2 {
      height: 35px;
      background: #ffffff;
      border: 1px solid #535252;
      box-sizing: border-box;
      border-radius: 2px;
      color: #535252;
      width: 168px;
      margin-top: 6px;
    }

    .space_delete {
      height: 100%;
      width: 100%;

      > div {
        flex: 1;
        text-align: end;
        padding-top: 4px;
      }
    }
  }
}
