.CardProductSearch {
  width: 100%;
  height: auto;
  border: 1px solid #e5e5e5;
  display: flex;

  .product_image {
    border: 0;
    flex: 0 0 200px;
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-color: #e5e5e5;
    cursor: pointer;
  }
  .Detail {
    flex: 1 1 auto;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    padding: 7px 14px 15px 21px;
    gap: 6px;

    > div:nth-child(1) {
      flex: 1 1 auto;
    }

    > div:nth-child(2) {
      flex: 0 0 auto;
    }

    .ProductDetail {
      display: flex;
      flex-direction: column;
      p {
        margin: 0;
      }
      .ProductName {
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        // Addition lines for 2 line or multiline ellipsis
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
      .Price {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        margin: 4px 0;
      }
      .Rate {
        color: #535252;
        font-size: 16px;
      }

      .description {
        margin-top: 14px;
        font-size: 11px;
        text-overflow: ellipsis;
        overflow: hidden;
        // Addition lines for 2 line or multiline ellipsis
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: normal;
      }

      Button {
        margin-top: 14px;
        color: #ffffff;
        width: 133px;
        height: 24px;
        background: #535252;
        border-radius: 2px;
        font-size: 10px;
        border-color: #535252;
      }
    }
  }
}
