.FooterNavigation {
  background-color: #e5e5e5;
  font-family: Lato;
  > div {
    margin: 0 auto;
    max-width: 1175px;
    padding: 40px 8px;
    width: 100%;
    overflow: hidden;

    h3 {
      font-weight: 800;
      font-size: 1.375rem;
      margin: 0px;
    }
  }

  .header {
    font-weight: 600;
    margin-bottom: 12px;
  }

  a {
    color: #58595b;
    font-size: 14px;
  }

  .qr__code {
    padding: 12px;
    background-color: white;
    height: 146px;
    width: 146px;
  }
}
