.nav__bar {
  width: 100%;
  margin: 0 auto;
  padding: 16px 8px;

  .button__icon {
    display: flex;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
  }

  .loging_btn {
    color: #535252;
  }

  .register_btn {
    border-radius: 2px;
    border-color: #535252;
    background: #535252;
    color: white;
  }

  .categoryBar {
    width: 100%;
    overflow-x: hidden;
  }
}
.category__dropdown {
  max-height: 240px;
  overflow-y: scroll;
}
