.modal_review {
  .collapse {
    margin-top: 18px;
  }

  .ant-modal-body {
    padding: 24px 0 0 0;
  }

  .ant-collapse-item-active {
    background-color: white;
    > div {
      background: white !important;
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        padding-bottom: 30px;
      }
    }
  }

  .ant-collapse-item-active:not(:first-child) {
    > div:nth-child(2) {
      padding: 30px 0;
    }
  }

  .ant-collapse-item {
    padding: 0 40px;
  }

  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 1px solid white;
  }
}

.panel__review {
  .header {
    color: #535252;
    .about_product {
      .name {
        font-weight: 600;
        font-size: 18px;
        width: 100%;
        max-width: 300px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .description {
        font-weight: 500;
        width: 100%;
        max-width: 300px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .rate {
      text-align: right;
    }
  }
  .media_btn,
  .control_btn {
    border-radius: 2px;
  }

  .media_btn {
    border-color: #979797;
    background: white;
    font-size: 12px;
    text-align: center;
    color: #535252;
    width: 109px;
    line-height: 15px;

    &.img {
      margin-right: 8px;
    }
  }

  .control_btn {
    width: 120px;
    font-size: 15px;
    line-height: 18px;
    border-color: #535252;

    &.cancel {
      background: white;
      color: #535252;
      margin-right: 8px;
    }

    &.confirm {
      background: #535252;
      color: white;
    }
  }
}
