.register_modal {
  text-align: center;
  padding: 32px 46px 66px 46px;

  h2 {
    margin-top: 6px;
    font-size: 30px;
    font-weight: 800;
    color: #03014c;
    margin-bottom: 10px;
  }

  p {
    font-size: 13.3232px;
    color: #03014c;
    opacity: 0.6;
    margin-bottom: 26px;
  }

  .input_login {
    padding: 14px 11px;
    border-radius: 4px;
    color: #03014c;
    text-align: left;
    opacity: 0.6;
    .ant-input-prefix {
      margin-right: 8px;
    }
  }

  .ant-form-item-explain {
    margin: 4px 0px;
    > div {
      text-align: left;
    }
  }

  .ant-form-item {
    margin: 0;
  }

  .register_btn {
    margin: 28px 0px;
    font-size: 13px;
    color: #03014c;
    width: 100%;
    height: 48px;
  }

  .login_btn {
    > a {
      font-size: 13px;
      text-decoration-line: underline;
      color: #03014c;
    }
  }
}
