.login_modal {
  text-align: center;
  padding: 32px 46px 66px 46px;

  h2 {
    margin-top: 6px;
    font-size: 30px;
    font-weight: 800;
    color: #03014c;
    margin-bottom: 10px;
  }

  p {
    font-size: 13.3232px;
    color: #03014c;
    opacity: 0.6;
    margin-bottom: 26px;
  }

  .input_login {
    padding: 14px 11px;
    border-radius: 4px;
    color: #03014c;
    text-align: left;
    opacity: 0.6;
    .ant-input-prefix {
      margin-right: 8px;
    }
  }

  .ant-form-item-explain {
    margin: 4px 0px;
    > div {
      text-align: left;
    }
  }

  .input_login:nth-child(2) {
    margin-top: 24px;
  }

  .remember_section {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    .ant-radio-wrapper {
      font-weight: 600;
      font-size: 13;
      opacity: 0.6;
      color: #03014c !important;
      .ant-radio-inner:after {
        background: #03014c !important;
      }

      .ant-radio-checked .ant-radio-inner,
      .ant-radio:hover .ant-radio-inner,
      .ant-radio-wrapper:hover,
      .ant-radio-input:focus .ant-radio-inner {
        border-color: #03014c !important;
      }
    }
    a {
      font-weight: 600;
      font-size: 13;
      color: #03014c !important;
      text-decoration-line: underline;
      // opacity: 0.5;
    }
  }

  .login_btn {
    margin-top: 32px;
    font-size: 13px;
    color: #03014c;
    width: 192px;
    height: 48px;
    background-color: #c4c4c433;
    border-color: #c4c4c433;
    font-weight: 600;
  }

  .whole_btn {
    margin-top: 24px;
    > a {
      font-size: 13px;
      text-decoration-line: underline;
      color: #03014c;
    }
  }
}
