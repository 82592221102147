.Fav {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 7px 14px;
  width: 26px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  border-radius: 2px;
}
