.product__views {
  padding: 25px;
  border: 1px solid #eeeeee;

  .control {
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: space-between;
  }

  .img_reviews {
    width: 92px;
    height: 92px;
    background-color: #e5e5e5;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
