.HomePage {
  .nav__section,
  .body__section {
    max-width: 1175px;
    margin: 0 auto;
  }

  .body__section {
    padding: 60px 8px;
  }
}
