.step__confirm {
  h1 {
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 28px;
    color: #58595b;
  }

  table {
    width: 100%;

    tr {
      width: 100%;
      display: flex;
      align-items: center;

      th {
        text-align: left;
        font-family: Poppins;
        font-weight: normal;
        font-size: 16px;
        text-align: left;
        color: #000000;
        padding: 12.5px 0;
        flex: 1;
      }

      td {
        text-align: left;
        font-family: Poppins;
        font-weight: normal;
        font-size: 16px;
        flex: 1;
      }

      th:not(:first-child),
      td:not(:first-child) {
        text-align: center;
        padding: 12px;
      }

      th:first-child,
      td:first-child {
        flex: 2;
        padding: 12px 0px;
      }
    }

    tbody {
      border-top: 0.5px solid #e5e5e5;
      border-bottom: 0.5px solid #e5e5e5;
      display: flex;
      align-items: center;
      padding: 12px 0px;
      flex-wrap: wrap;
    }

    .product__col {
      display: flex;
      .product_img {
        border: 1px solid #f1f1f1;
        flex: 0 0 73px;
        height: 73px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
      }
      .product_des {
        flex: 1 1 auto;
        color: #000000;
        margin-left: 23px;
        .product_name {
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // -webkit-box-orient: vertical;
          // overflow: hidden;
          width: 100%;
          font-weight: 600;
        }
        .product_variant {
          // display: -webkit-box;
          // -webkit-line-clamp: 1;
          // -webkit-box-orient: vertical;
          // overflow: hidden;
          width: 100%;
        }
      }
    }
    .product_qty {
      font-family: Roboto;
    }
  }

  .btn_next {
    margin-top: 42px;
    text-align: right;
    > .ant-btn {
      height: 35px;
      background: #535252;
      border-color: #535252;
      border-radius: 2px;
      color: white;
      font-size: 13px;
      font-family: Poppins;
      width: 168px;
    }

    .disabled_btn {
      background: #cacaca;
      color: "white";
      border-color: #cacaca;
    }
  }
}

.step__Inform {
  .inform_detail {
    background: #f1f1f1;
    padding: 50px 42px;
    .card__inform {
      background: #ffffff;
      border: 0.1px solid #e5e5e5;
      box-sizing: border-box;
      padding: 12px;
      color: #58595b;
      > div:nth-child(1) {
        font-size: 15px;
        line-height: 18px;
        border-bottom: 0.1px solid #9797972a;
        padding-bottom: 10px;
      }
      > div:nth-child(2) {
        margin-top: 4px;
        font-size: 14px;
        line-height: 16px;
        > div {
          padding: 12px 16px;
        }

        .total {
          font-weight: 500;
        }
      }
    }

    .card__summary {
      > div:nth-child(2) {
        > div {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
      }
    }
  }

  .inform_payment {
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 16px 32px;

    h1 {
      font-weight: 500;
      font-size: 18px;
      color: #000000;
      margin-bottom: 30px;
    }

    .ant-radio-inner:after {
      background: #535252 !important;
    }

    .ant-radio-checked .ant-radio-inner,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-wrapper:hover,
    .ant-radio-input:focus .ant-radio-inner {
      border-color: #535252 !important;
    }

    .upload__section {
      span {
        // width: 100%;
        // display: flex;
        .ant-upload {
          width: 100%;
          button {
            width: 100%;
            height: 28px;
            border-radius: 2px;
            font-size: 12px;
            background: #979797;
            border-color: #979797;
            > span {
              width: 100%;
              color: white;
              text-align: center;
              display: block;
            }
          }
        }
      }
      .ant-upload-span {
        * {
          color: #535252;
        }
      }
    }
  }

  .btn_control {
    margin-top: 24px;
    text-align: right;

    .ant-btn {
      height: 35px;
      border-radius: 2px;
      font-size: 13px;
      font-family: Poppins;
      width: 168px;
    }

    > div:nth-child(1) {
      > .ant-btn {
        background: #535252;
        border-color: #535252;
        color: white;
      }
    }
    > div:nth-child(2) {
      margin-top: 11px;
      > .ant-btn {
        background: white;
        color: #535252;
        border-color: #535252;
      }
    }
  }
}

.step__receipt {
  > div:nth-child(1) {
    text-align: center;
    color: #535252;
    .title_thank {
      font-size: 18px;
    }
    .sub_title_thank {
      margin-top: 3px;
      font-size: 12px;
    }
  }

  .information {
    margin-top: 33px;
    > div:nth-child(1) {
      font-size: 18px;
      padding: 20px 40px;
      background: #f9f9f9;
    }
    > div:nth-child(2) {
      padding: 43px 125px;
    }
    .head__text {
      font-size: 16px;
    }
    .value__text {
      font-size: 14px;
      color: #464a53;
    }
  }

  .detail {
    margin-top: 33px;
    > div:nth-child(1) {
      font-size: 18px;
      padding: 20px 40px;
      background: #f9f9f9;
    }
    > div:nth-child(2) {
      overflow-x: scroll;
    }
    table {
      min-width: 700px;
      margin: 35px 0;
      width: 100%;
      border-collapse: collapse;
      th,
      td {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        // flex: 1;
        // display: -webkit-box;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        width: 100%;
      }
      th {
        padding: 10px;
        color: #464a53;
        background-color: #e5e5e5;
        color: black;
        &:nth-child(1) {
          text-align: left;
        }
      }
      td {
        padding: 10px;
        color: black;
        width: 100%;
      }
      tr {
        // border: 1px solid #e5e5e5;
        display: grid;
        grid-template-columns: 3fr 1fr 1fr 1fr 2fr;
        align-items: center;
      }
      tbody {
        tr {
          td:nth-child(1) {
            text-align: left;
            .product_name {
              // display: -webkit-box;
              // -webkit-line-clamp: 2;
              // -webkit-box-orient: vertical;
              // overflow: hidden;
              width: 100%;
            }
            .product_varient {
              // display: -webkit-box;
              // -webkit-line-clamp: 1;
              // -webkit-box-orient: vertical;
              // overflow: hidden;
              width: 100%;
            }
          }
          &:nth-child(even) {
            background-color: #f8f7f7;
          }
        }
      }
    }
  }

  .btn_control {
    text-align: center;

    .ant-btn {
      height: 40px;
      border-radius: 2px;
      font-size: 18px;
      width: 246px;
    }

    > .ant-btn:nth-child(2) {
      background: #535252;
      border-color: #535252;
      color: white;
    }

    > .ant-btn:nth-child(1) {
      background: white;
      color: #000000;
      border-color: #535252;
      margin-right: 16px;
    }
  }

  .confirm__payment__page .body {
    margin-top: 27px;

    > .title_page {
      text-align: center;
      color: #535252;
      > div:nth-child(1) {
        font-size: 18px;
      }
      > div:nth-child(2) {
        font-size: 12px;
      }
    }

    .subtitle_section {
      background: #f9f9f9;
      padding: 21px 28px;
      color: #000000;
      font-size: 18px;
    }

    .box__detail {
      color: #58595b;
      padding: 13px 20px;
      border: 1px solid #58595b10;
      .box__head {
      }
      .box__body {
        border-top: 1px solid #97979710;
        padding: 13px;
        margin-top: 13px;
        > div:not(:first-child) {
          margin-top: 9px;
        }
      }
    }

    .confirm_btn {
      color: white;
      width: 246px;
      height: 40px;
      background: #535252;
      border-color: #535252;
      border-radius: 2px;
    }
  }
}
