.CardCategory {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 129px;
  border: 1px solid #dfe3e9;
  border-radius: 8px;
  padding: 15px 20px;
  .Left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 60%;
    p {
      margin-bottom: 0em;
    }
    .Category {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
    }
    .Product {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      margin: 0.75em 0;
    }
    .Shop {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .Right {
    flex: 0 0 40%;
    background-size: contain;
    border: 1px solid #e5e5e5;
    background-position: center;
    background-repeat: no-repeat;
  }
}
