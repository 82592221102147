.CardProducts {
  width: 100%;
  height: auto;
  border: 1px solid #e5e5e5;

  .product_image {
    border: 0;
    width: 100%;
    position: relative;
    padding-top: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-color: #e5e5e5;
    cursor: pointer;
  }
  .Detail {
    display: inline-flex;
    justify-content: space-between;
    padding: 7px 14px 15px 21px;
    gap: 6px;

    > div:nth-child(1) {
      flex: 1 1 auto;
    }

    > div:nth-child(2) {
      flex: 0 0 auto;
    }

    .ProductDetail {
      display: flex;
      flex-direction: column;
      p {
        margin: 0;
      }
      .ProductName {
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
      }
      .Price {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
      }
      .Rate {
        color: #535252;
        font-size: 14px;
      }
      Button {
        margin-top: 20px;
        color: #ffffff;
        width: 133px;
        height: 24px;
        background: #535252;
        border-radius: 2px;
        font-size: 10px;
        border-color: #535252;
      }
    }
  }
}
